const GlobalStore = {
    isLoadRouterView: false,

    settingMenuShow: false,
    settingMenuShow_create: false,
    settingMenuShow_update: false,
    settingMenuShow_delete: false,

    menuAdsDesign: false,
    menuAdsDesign_create: false,
    menuAdsDesign_update: false,
    menuAdsDesign_delete: false,

    menuCampaign: false,
    menuCampaign_create: false,
    menuCampaign_update: false,
    menuCampaign_delete: false,

    menuLeadsPeek: false,
    menuLeadsPeek_create: false,
    menuLeadsPeek_update: false,
    menuLeadsPeek_delete: false,
    menuLeadsPeek_type: '',
    menuLeadsPeek_typeLocator:'',

    menuDashboard: false,

    menuUserType: '',
    systemUser: false,
    idsys:'',
    rootcomp: false,
    agencyplatformroot:false,
    companyrootname:'',
    companyrootlegalname:'',
    companyrootphone:'',
    companyrootnameshort:'',
    companyrootaddres:'',
    companyrootcity:'',
    companyrootzip:'',
    companyrootstatecode:'',
    companyrootstatename:'',
    companyrootdomain:'',
    companyrootsubdomain:'',

    systemTimezone: 'America/Chicago',
    clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    
    userrootname:'',
    userrootemail:'',
    userrootaddres:'',
    userrootcity:'',
    userrootzip:'',
    userrootstatecode:'',
    userrootstatename:'',
    sppubkey: '',
    recapkey: '',
    
    charges_enabled: false,
    payouts_enabled: false,
    account_requirements: '',

    rootpaymentterm: '',

    creditcardsetup: false,
    stripescriptload: false,
    questionnairesetup: false,
    stripeaccountconnected:false,
    statusaccountconnected: '',

    disabledaddcampaign: false,

    selectsGroupCompany: {
        companyGroupList : [],
    },

    QuestionnaireCurrsection : 1,
    QuestionnairePrevsection : 1,
    Questionnaire_local_LeadspeekMinCostMonth: 0,
    Questionnaire_local_LeadspeekCostperlead: 0,

    clientPaymentFailed: false,
    failedCampaignNumber: [],
    failedInvoiceAmount:[],
    
    /** FOR PHOTO AND COMPANY TITLE */
    globalCompanyName: 'Company Name',
    globalCompanyPhoto: '',
    globalProfilePhoto: '',
    globalBoxBgColor: 'ffffff',
    globalTextColor: '',
    globalLinkColor: '',
    
    globalTemplateBgColor: '',
    globalSidebarBgColor: '',
    globalFontTheme: '',

    globalviewmode:false,

    globalClient: {
      name: '',
      address: '',
      city: '',
      zip: '',
      fulladdress: '',
      statename: '',
      companyname: '',
      companyemail: '',
      companyfulladdress:'',
    },

    globalModulNameLink: {
        local: { 
            name: 'Site ID',
            url: 'siteid',
        },
        locator: {
            name: 'Search ID',
            url: 'searchid',
        },
        enhance: {
            name: 'Enhance ID',
            url: 'enhanceid',
        }
    },

  initAllMenu() {
    this.settingMenuShow = false;
    this.settingMenuShow_create = false,
    this.settingMenuShow_update = false,
    this.settingMenuShow_delete = false,

    this.menuAdsDesign = false;
    this.menuAdsDesign_create = false;
    this.menuAdsDesign_update = false;
    this.menuAdsDesign_delete = false;

    this.menuCampaign = false;
    this.menuCampaign_create = false;
    this.menuCampaign_update = false;
    this.menuCampaign_delete = false;

    this.menuLeadsPeek = false;
    this.menuLeadsPeek_create = false;
    this.menuLeadsPeek_update = false;
    this.menuLeadsPeek_delete = false;
    this.menuLeadsPeek_type = '',
    this.menuLeadsPeek_typeLocator = '',

    this.menuDashboard = false;

  },
  checkClientModule(setupComplete,accessmodule) {
    this.initAllMenu();
    
    if (setupComplete == 'F') {
      return;
    }
    //console.log(accessmodule);
    this.menuAdsDesign = false;
    this.menuCampaign = false;
    this.settingMenuShow = false;
    this.menuLeadsPeek = false;
    this.menuLeadsPeek_update = false;
    
    if (accessmodule['leadspeek']) {
      this.menuLeadsPeek = true;
      this.menuLeadsPeek_update = true;
      this.menuLeadsPeek_delete = true;
    }
    
    var lptype = accessmodule['leadspeek_type'];
    lptype = lptype.split('|');
    for(var i=0;i<lptype.length;i++) {
      if (lptype[i] == 'local') {
        this.menuLeadsPeek_type = lptype[i];
      }else{
        this.menuLeadsPeek_typeLocator = lptype[i];
      }
    }
    
  },
  checkModuleRole(roleModuleUser,setupComplete) {
    //console.log(roleModuleUser);
    this.initAllMenu();

    if (setupComplete == 'F') {
      return;
    }
    
    for(var i=0;i<roleModuleUser.length;i++) {
      if (roleModuleUser[i]['module_name'] == 'Ads Design') {
          this.menuAdsDesign = roleModuleUser[i]['enable_permission'];
          this.menuAdsDesign_create = roleModuleUser[i]['create_permission'];
          this.menuAdsDesign_update = roleModuleUser[i]['update_permission'];
          this.menuAdsDesign_delete = roleModuleUser[i]['delete_permission'];

        }else if (roleModuleUser[i]['module_name'] == 'Campaign') {
          this.menuCampaign = roleModuleUser[i]['enable_permission'];
          this.menuCampaign_create = roleModuleUser[i]['create_permission'];
          this.menuCampaign_update = roleModuleUser[i]['update_permission'];
          this.menuCampaign_delete = roleModuleUser[i]['delete_permission'];

        }else if (roleModuleUser[i]['module_name'] == 'LeadsPeek') {
          //console.log(this.roleModuleUser[i]['module_name'] + ' | ' + this.roleModuleUser[i]['enable_permission']);
          this.menuLeadsPeek = roleModuleUser[i]['enable_permission'];
          this.menuLeadsPeek_create = roleModuleUser[i]['create_permission'];
          this.menuLeadsPeek_update = roleModuleUser[i]['update_permission'];
          this.menuLeadsPeek_delete = roleModuleUser[i]['delete_permission'];

        }else if (roleModuleUser[i]['module_name'] == 'Settings') {
          this.settingMenuShow = roleModuleUser[i]['enable_permission'];
          this.settingMenuShow_create = roleModuleUser[i]['create_permission'];
          this.settingMenuShow_update = roleModuleUser[i]['update_permission'];
          this.settingMenuShow_delete = roleModuleUser[i]['delete_permission'];
        }
    }  
  },

  initStripeScriptLoad(status) {
    this.stripescriptload = status;
  },

  /** GENERAL FUNCTION */
  formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  },
  
  formatMoney(amount) {
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  },

  formatCurrency(input,val, blur) {
    if (typeof val !== "number") {
      return val;
    }
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    });
    return formatter.format(val);
    
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.
    
    var input_val = '';
    // get input value
    if (typeof(val) != 'undefined' && val != '') {
        input_val = val;
    }else{
         input_val = input.val();
    }
    input_val = String(input_val);

    //console.log("INPUT : " + input_val + ' | ' + input_val.indexOf("."));
    // don't validate empty input
    if (input_val === "") { return; }
    
    // original length
    var original_len = input_val.length;
  
    // initial caret position 
    var caret_pos = '';
    if (typeof(val) != 'undefined' && val != '') {
        caret_pos = '';
    }else{
        caret_pos = input.prop("selectionStart");
    }
      
    // check for decimal
    if (input_val.indexOf(".") >= 0) {
  
      // get position of first decimal
      // this prevents multiple decimals from
      // being entered
      var decimal_pos = input_val.indexOf(".");
  
      // split number by decimal point
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
  
      // add commas to left side of number
      left_side = this.formatNumber(left_side);
  
      // validate right side
      right_side = this.formatNumber(right_side);
      
      // On blur make sure 2 numbers after decimal
      if (blur === "blur") {
        right_side += "00";
      }
      
      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);
  
      // join number by .
      //input_val = "$" + left_side + "." + right_side;
      input_val = left_side + "." + right_side;

    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = this.formatNumber(input_val);
      //input_val = "$" + input_val;
      input_val = input_val;

      // final formatting
      if (blur === "blur") {
        input_val += ".00";
      }
    }
    
    // send updated string to input
    if (typeof(val) != 'undefined' && val != '') {
        return input_val;
    }else{
        input.val(input_val);
    
        // put caret back in the right position
        var updated_len = input_val.length;
        caret_pos = updated_len - original_len + caret_pos;
        input[0].setSelectionRange(caret_pos, caret_pos);
    }
  },
  SetlocalStorage(storname,storval) {
    const envryptedObjectOri = CryptoJS.AES.encrypt(JSON.stringify(storval),'3ZY@l9qxdwL6');
    localStorage.setItem(storname, envryptedObjectOri);
  },
  getlocalStorage(storname) {
    const denvryptedObjectOri = localStorage.getItem(storname);
    return JSON.parse(CryptoJS.AES.decrypt(denvryptedObjectOri,'3ZY@l9qxdwL6').toString(CryptoJS.enc.Utf8));
  },
  
  /** GENERAL FUNCTION */
};

const Global = {
    install(Vue, options) {
      let app = new Vue({
        data: {
          globalStore: GlobalStore
        }
      });
      Vue.prototype.$global = app.globalStore;
    }
  };
  
  export default Global;