<template>
  <div
    ref="parent"
    :class="[{
      'is-focused': isFocus,
      'has-value': value,
      'has-hint': hint,
      'has-error': error,
      'is-disabled': disabled,
      'is-dark': dark,
      'no-flags': noFlags,
      'has-list-open': hasListOpen,
      'is-valid': valid
    }, size]"
    class="country-selector"
    @blur.capture="handleBlur"
    @mouseenter="updateHoverState(true)"
    @mouseleave="updateHoverState(false)"
  >
  <div
      v-if="value && !noFlags"
      class="country-selector__country-flag"
      @click.stop="toggleList"
    >
      <div :class="`iti-flag-small iti-flag ${value.toLowerCase()}`" />
    </div>
    <input
      :id="id"
      ref="CountrySelector"
      :value="callingCode"
      :placeholder="label"
      :disabled="disabled"
      class="country-selector__input"
      readonly
      @focus="isFocus = true"
      @keydown="keyboardNav"
      @click.stop="toggleList"
    >
    <div
      class="country-selector__toggle"
      @click.stop="toggleList"
    >
      <slot name="arrow">
        <svg
          mlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          class="country-selector__toggle__arrow"
        >
          <path
            class="arrow"
            d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
          />
          <path
            fill="none"
            d="M0 0h24v24H0V0z"
          />
        </svg>
      </slot>
    </div>
    <label
      ref="label"
      v-if="showLabel"
      class="country-selector__label"
      @click.stop="toggleList"
    >
      {{ hint || label }}
    </label>
    <Transition name="slide">
      <div
        v-show="hasListOpen"
        ref="countriesList"
        class="country-selector__list"
        :class="{ 'has-calling-code': showCodeOnList }"
      >
        <div
        v-for="item in countriesSorted"
        :key="item.iso2"
          key-field="iso2"
        >
          <button
            :key="`item-${item.code}`"
            :class="[
              { 'selected': value === item.iso2 },
              { 'keyboard-selected': value !== item.iso2 && tmpValue === item.iso2 }
            ]"
            class="d-flex align-items-center country-selector__list__item"
            :style="[
              itemHeight,
            ]"
            tabindex="-1"
            type="button"
            @click.stop="updateValue(item.iso2)"
          >
            <div
              v-if="!noFlags"
              class="country-selector__list__item__flag-container"
            >
              <div :class="`iti-flag-small iti-flag ${item.iso2.toLowerCase()}`" />
            </div>
            <span
              v-if="showCodeOnList"
              class="country-selector__list__item__calling-code flex-fixed"
            >+{{ item.dialCode }}</span>
            <div class="dots-text">
              {{ item.name }}
            </div>
          </button>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
  import { getCountryCallingCode } from 'libphonenumber-js'
  // import StylesHandler from '@/VuePhoneNumberInput/mixins/StylesHandler'

  // import { RecycleScroller } from 'vue-virtual-scroller'

  export default {
    name: 'CountrySelector',
    // components: {
    //   RecycleScroller
    // },
    // mixins: [StylesHandler],
    props: {
      id: { type: String, default: 'CountrySelector' },
      value: { type: [String, Object], default: null },
      label: { type: String, default: 'Choose country' },
      hint: { type: String, default: String },
      size: { type: String, default: String },
      error: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      valid: { type: Boolean, default: false },
      dark: { type: Boolean, default: false },
      items: { type: Array, default: Array, required: true },
      preferredCountries: { type: Array, default: null },
      onlyCountries: { type: Array, default: null },
      ignoredCountries: { type: Array, default: null },
      noFlags: { type: Boolean, default: false },
      countriesHeight: { type: Number, default: 35 },
      showCodeOnList: { type: Boolean, default: false },
      showLabel: { type: Boolean, default: true }
    },
    data () {
      return {
        isFocus: false,
        hasListOpen: false,
        selectedIndex: null,
        tmpValue: this.value,
        query: '',
        indexItemToShow: 0,
        isHover: false
      }
    },
    computed: {
      itemHeight () {
        return {
          height: `${this.countriesHeight}px`
        }
      },
      listHeight () {
        return {
          height: `${(this.countriesHeight + 1) * 7}px`,
          maxHeight: `${(this.countriesHeight + 1) * 7}px`
        }
      },
      countriesList () {
        return this.items.filter(item => !this.ignoredCountries.includes(item.iso2))
      },
      countriesFiltered () {
        const countries = this.onlyCountries || this.preferredCountries
        return countries.map(country => this.countriesList.find(item => item.iso2.includes(country)))
      },
      otherCountries () {
        return this.countriesList.filter(item => !this.preferredCountries.includes(item.iso2))
      },
      countriesSorted () {
        return this.preferredCountries
          ? [ ...this.countriesFiltered,
              ...this.otherCountries ]
          : this.onlyCountries
            ? this.countriesFiltered
            : this.countriesList
      },
      selectedValueIndex () {
        return this.value
          ? this.countriesSorted.findIndex(c => c.iso2 === this.value)
          : null
      },
      tmpValueIndex () {
        return this.countriesSorted.findIndex(c => c.iso2 === this.tmpValue)
      },
      callingCode () {
        return this.value ? `+${getCountryCallingCode(this.value)}` : null
      }
    },
    methods: {
      updateHoverState(value) {
        this.isHover = value
      },
      handleBlur (e) {
        if (this.$el.contains(e.relatedTarget)) return
        this.isFocus = false
        this.closeList()
      },
      toggleList () {
        this.$refs.countriesList.offsetParent ? this.closeList() : this.openList()
      },
      openList () {
        if (!this.disabled) {
          this.$refs.CountrySelector.focus()
          this.$emit('open')
          this.isFocus = true
          this.hasListOpen = true
          if (this.value) this.scrollToSelectedOnFocus(this.selectedValueIndex)
        }
      },
      closeList () {
        this.$emit('close')
        this.hasListOpen = false
      },
      async updateValue (val) {
        this.tmpValue = val
        this.$emit('input', val || null)
        await this.$nextTick()
        this.closeList()
      },
      scrollToSelectedOnFocus (arrayIndex) {
        this.$nextTick(() => {
          // this.indexItemToShow = arrayIndex - 3
          this.$refs.countriesList.scrollTop = arrayIndex * (this.countriesHeight + 1) - ((this.countriesHeight + 1) * 3)
        })
      },
      keyboardNav (e) {
        const code = e.keyCode
        if (code === 40 || code === 38) {
          // arrow up down
          if (e.view && e.view.event) {
            // TODO : It's not compatible with FireFox
            e.view.event.preventDefault()
          }
          if (!this.hasListOpen) this.openList()
          let index = code === 40 ? this.tmpValueIndex + 1 : this.tmpValueIndex - 1
          if (index === -1 || index >= this.countriesSorted.length) {
            index = index === -1
              ? this.countriesSorted.length - 1
              : 0
          }
          this.tmpValue = this.countriesSorted[index].iso2
          this.scrollToSelectedOnFocus(index)
        } else if (code === 13) {
          // enter
          this.hasListOpen ? this.updateValue(this.tmpValue) : this.openList()
        } else if (code === 27) {
          // escape
          this.closeList()
        } else {
          // typing a country's name
          this.searching(e)
        }
      },
      searching (e) {
        const code = e.keyCode
        clearTimeout(this.queryTimer)
        this.queryTimer = setTimeout(() => {
          this.query = ''
        }, 1000)
        const q = String.fromCharCode(code)
        if (code === 8 && this.query !== '') {
          this.query = this.query.substring(0, this.query.length - 1)
        } else if (/[a-zA-Z-e ]/.test(q)) {
          if (!this.hasListOpen) this.openList()
          this.query += e.key
          const countries = this.preferredCountries ? this.countriesSorted.slice(this.preferredCountries.length) : this.countriesSorted
          const resultIndex = countries.findIndex(c => {
            this.tmpValue = c.iso2
            return c.name.toLowerCase().startsWith(this.query)
          })
          if (resultIndex !== -1) {
            this.scrollToSelectedOnFocus(resultIndex + (this.preferredCountries ? this.preferredCountries.length : 0))
          }
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import './assets/iti-flags/flags.css';

  // Light Theme
 .country-selector {
	 font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	 position: relative;
	 height: 40px;
	 min-height: 40px;
	 z-index: 0;
	 user-select: none;
}
 .country-selector:hover {
	 z-index: 1;
}
 .country-selector__label {
	 position: absolute;
	 top: 3px;
	 cursor: pointer;
	 left: 11px;
	 transform: translateY(25%);
	 opacity: 0;
	 transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
	 font-size: 11px;
	//  color: #747474;
}
 .country-selector__input {
	 cursor: pointer;
	 background-color: transparent;
	 position: relative;
	 width: 100%;
	 height: 40px;
	 min-height: 40px;
	 padding-right: 22px;
	 font-weight: 400;
	 appearance: none;
	 outline: none;
	 border: 1px solid #cad1d7;
   border-radius: 6px 0px 0px 6px;
	 font-size: 13px;
	 z-index: 0;
	 padding-left: 8px;
	 color: var(--gray-900);
}
 .country-selector__toggle {
	 position: absolute;
	 right: 5px;
	 top: calc(50% - 10px);
	 transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
	 text-align: center;
	 display: inline-block;
	 cursor: pointer;
	 height: 24px;
}
 .country-selector__toggle__arrow {
	//  color: #747474;
}
 .country-selector__toggle__arrow path.arrow {
	 fill: #747474;
}
 .country-selector__country-flag {
	 margin: auto 0;
	 position: absolute;
	 top: 21px;
	 left: 11px;
	 z-index: 1;
	 cursor: pointer;
}
 .country-selector__country-flag img {
	 position: absolute;
}
 .country-selector__list {
	 max-width: 100%;
	 top: 100%;
	 width: 100%;
	 min-width: 230px;
   max-height: 200px;
   overflow-y: scroll;
	 position: absolute;
	 background-color: white;
	 overflow: hidden;
	 box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	 z-index: 9;
	 list-style: none;
	 overflow-y: auto;
	 overflow-x: hidden;
	 padding: 0;
	 margin: 0;
}
 .country-selector__list.has-calling-code {
	 min-width: 270px;
}
 .country-selector__list__item {
	 padding: 0 10px;
	 text-overflow: ellipsis;
	 white-space: nowrap;
	 overflow: hidden;
	 font-size: 12px;
	 cursor: pointer;
	 background-color: transparent;
	 width: 100%;
	 border: 0;
	 outline: none;
}
 .country-selector__list__item__flag-container {
	 margin-right: 10px;
}
 .country-selector__list__item__calling-code {
	 width: 45px;
	//  color: #747474;
}
 .country-selector__list__item:hover, .country-selector__list__item.keyboard-selected {
	 background-color: #f2f2f2;
}
 .country-selector__list__item.selected {
	 color: #fff;
	 font-weight: 600;
   background: #43455e;
}
 .country-selector__list__item.selected .country-selector__list__item__calling-code {
	 color: #fff;
}
 .country-selector.is-dark .country-selector__input {
	 cursor: pointer;
	 color: rgba(255, 255, 255, 0.7);
}
 .country-selector.is-dark .country-selector__input::-webkit-input-placeholder {
	 color: rgba(255, 255, 255, 0.7);
}
 .country-selector.is-dark .country-selector__input::-moz-placeholder {
	 color: rgba(255, 255, 255, 0.7);
}
 .country-selector.is-dark .country-selector__input:-ms-input-placeholder {
	 color: rgba(255, 255, 255, 0.7);
}
 .country-selector.is-dark .country-selector__input::-ms-input-placeholder {
	 color: rgba(255, 255, 255, 0.7);
}
 .country-selector.is-dark .country-selector__input:-moz-placeholder {
	 color: rgba(255, 255, 255, 0.7);
}
 .country-selector.is-dark .country-selector__input::placeholder {
	 color: rgba(255, 255, 255, 0.7);
}
 .country-selector.is-dark .country-selector__toggle__arrow {
	 color: rgba(255, 255, 255, 0.7);
}
 .country-selector.is-dark .country-selector__toggle__arrow path.arrow {
	 fill: rgba(255, 255, 255, 0.7);
}
 .country-selector.is-dark .country-selector__list__item {
	 color: #f2f2f2;
}
 .country-selector.is-dark .country-selector__list__item:hover, .country-selector.is-dark .country-selector__list__item.keyboard-selected {
	 background-color: #43455e;
}
 .country-selector.is-dark .country-selector__list__calling-code {
	 color: rgba(255, 255, 255, 0.3);
}
 .country-selector.is-dark .country-selector__input, .country-selector.is-dark .country-selector__list {
	 color: rgba(255, 255, 255, 0.7);
}
 .country-selector.has-list-open {
	 z-index: 1;
}
 .country-selector.has-list-open .country-selector__toggle {
	 transform: rotate(180deg);
}
 .country-selector.is-focused {
	 z-index: 1;
}
 .country-selector.has-error .country-selector__input {
	 border-color: orangered;
}
 .country-selector.has-error .country-selector__label {
	 color: orangered;
}
 .country-selector.has-value .country-selector__input {
	 padding-left: 40px;
}
 .country-selector.has-value .country-selector__label, .country-selector.has-hint .country-selector__label {
	 opacity: 1;
	 transform: translateY(0);
	 font-size: 11px;
}
 .country-selector.has-value .country-selector__input, .country-selector.has-hint .country-selector__input {
	 padding-top: 14px;
}
 .country-selector.is-disabled .country-selector {
	 cursor: not-allowed;
}
 .country-selector.is-disabled .country-selector__input {
	 border-color: #ccc;
	 background-color: #f2f2f2;
	 color: #ccc;
}
 .country-selector.is-disabled .country-selector__input::-webkit-input-placeholder {
	 color: #ccc;
}
 .country-selector.is-disabled .country-selector__input::-moz-placeholder {
	 color: #ccc;
}
 .country-selector.is-disabled .country-selector__input:-ms-input-placeholder {
	 color: #ccc;
}
 .country-selector.is-disabled .country-selector__input::-ms-input-placeholder {
	 color: #ccc;
}
 .country-selector.is-disabled .country-selector__input:-moz-placeholder {
	 color: #ccc;
}
 .country-selector.is-disabled .country-selector__input::placeholder {
	 color: #ccc;
}
 .country-selector.is-disabled .country-selector__label, .country-selector.is-disabled .country-selector__input, .country-selector.is-disabled .country-selector__toggle__arrow, .country-selector.is-disabled .country-selector__country-flag, .country-selector.is-disabled .country-selector__country-flag > div {
	 cursor: not-allowed;
	 color: #ccc;
}
 .country-selector.no-flags .country-selector__input {
	 padding-left: 10px;
}
 .country-selector.sm {
	 height: 36px;
	 min-height: 36px;
}
 .country-selector.sm .country-selector__input {
	 height: 36px;
	 min-height: 36px;
	 font-size: 12px;
}
 .country-selector.sm .country-selector__label {
	 font-size: 10px;
}
 .country-selector.sm .country-selector__country-flag {
	 top: 16px;
}
 .country-selector.sm .country-selector__country-flag img {
	 zoom: 0.3;
	 color: red;
	/* stylelint-disable */
	 -moz-transform: scale(0.3);
	 -moz-transform-origin: 0 0;
	/* stylelint-enable */
}
 .country-selector.sm.has-value .country-selector__input {
	 padding-top: 12px;
}
 .country-selector.lg {
	 height: 48px;
	 min-height: 48px;
}
 .country-selector.lg .country-selector__input {
	 height: 48px;
	 min-height: 48px;
	 font-size: 14px;
}
 .country-selector.lg .country-selector__label {
	 font-size: 14px;
}
 .country-selector.lg .country-selector__country-flag {
	 top: 25px;
}
 .country-selector.lg .country-selector__country-flag img {
	 zoom: 0.45;
	/* stylelint-disable */
	 -moz-transform: scale(0.45);
	 -moz-transform-origin: 0 0;
	/* stylelint-enable */
}
 .country-selector.lg.has-value .country-selector__input {
	 padding-top: 18px;
}
 .country-selector .slide-enter-active, .country-selector .slide-leave-active {
	 opacity: 1;
	 z-index: 998;
	 transition: all 0.3s;
	 transform: translateY(0);
}
 .country-selector .slide-enter, .country-selector .slide-leave-to {
	/* .fade-leave-active below version 2.1.8 */
	 opacity: 0;
	 z-index: 998;
	 transform: translateY(-20px);
}
 
</style>
