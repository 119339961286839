const formatCurrencyUSD = (price) => {
    if (isNaN(price) || price === '') {
        return '0';
    }

    if (price.toString().endsWith('.')) {
        return price.toString().replace(/\.$/, '');
    }

    if (price.toString().includes('.')) {
        return price.toString();
    } else {
        return parseInt(price).toString();
    }
}

export { formatCurrencyUSD }