<template>
  <card class="card-stats" :show-footer-line="true" >
    <div class="row">
      <div class="col-5" v-if="$slots.icon || icon">
        <div class="info-icon text-center" :class="`icon-${type}`">
          <slot name="icon"> <i :class="icon"></i> </slot>
        </div>
      </div>
      <div class="col-7" v-if="$slots.content || title || subTitle">
        <div class="numbers">
          <slot>
            <p v-if="subTitle" class="card-category">{{ subTitle }}</p>
            <h3 v-if="title" class="card-title" :style="{color: isProfit && profitAmount > 0 && 'green !important'}">
              <i class='el-icon-top' v-if="isProfit && profitAmount > 0" style="font-size: 20px;"></i>
              {{ title }}
            </h3>
          </slot>
        </div>
      </div>
    </div>
    <div class="stats" slot="footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </card>
</template>
<script>
import Card from './Card.vue';

export default {
  name: 'stats-card',
  components: {
    Card
  },
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    icon: String,
    title: String,
    subTitle: String,
    isProfit: Boolean,
  },
  data() {
    return {
      profitAmount: 0,
    }
  },
  mounted() {
    let amountWithoutDollarSign = this.title.replace('$', '');
    this.profitAmount = amountWithoutDollarSign
  },
};
</script>
<style>
.card-stats .stats{
  color: var(--text-primary-color);
}
</style>
