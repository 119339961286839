var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"parent",staticClass:"country-selector",class:[{
    'is-focused': _vm.isFocus,
    'has-value': _vm.value,
    'has-hint': _vm.hint,
    'has-error': _vm.error,
    'is-disabled': _vm.disabled,
    'is-dark': _vm.dark,
    'no-flags': _vm.noFlags,
    'has-list-open': _vm.hasListOpen,
    'is-valid': _vm.valid
  }, _vm.size],on:{"!blur":function($event){return _vm.handleBlur($event)},"mouseenter":function($event){return _vm.updateHoverState(true)},"mouseleave":function($event){return _vm.updateHoverState(false)}}},[(_vm.value && !_vm.noFlags)?_c('div',{staticClass:"country-selector__country-flag",on:{"click":function($event){$event.stopPropagation();return _vm.toggleList($event)}}},[_c('div',{class:("iti-flag-small iti-flag " + (_vm.value.toLowerCase()))})]):_vm._e(),_c('input',{ref:"CountrySelector",staticClass:"country-selector__input",attrs:{"id":_vm.id,"placeholder":_vm.label,"disabled":_vm.disabled,"readonly":""},domProps:{"value":_vm.callingCode},on:{"focus":function($event){_vm.isFocus = true},"keydown":_vm.keyboardNav,"click":function($event){$event.stopPropagation();return _vm.toggleList($event)}}}),_c('div',{staticClass:"country-selector__toggle",on:{"click":function($event){$event.stopPropagation();return _vm.toggleList($event)}}},[_vm._t("arrow",[_c('svg',{staticClass:"country-selector__toggle__arrow",attrs:{"mlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{staticClass:"arrow",attrs:{"d":"M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"}}),_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0V0z"}})])])],2),(_vm.showLabel)?_c('label',{ref:"label",staticClass:"country-selector__label",on:{"click":function($event){$event.stopPropagation();return _vm.toggleList($event)}}},[_vm._v(" "+_vm._s(_vm.hint || _vm.label)+" ")]):_vm._e(),_c('Transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasListOpen),expression:"hasListOpen"}],ref:"countriesList",staticClass:"country-selector__list",class:{ 'has-calling-code': _vm.showCodeOnList }},_vm._l((_vm.countriesSorted),function(item){return _c('div',{key:item.iso2,attrs:{"key-field":"iso2"}},[_c('button',{key:("item-" + (item.code)),staticClass:"d-flex align-items-center country-selector__list__item",class:[
            { 'selected': _vm.value === item.iso2 },
            { 'keyboard-selected': _vm.value !== item.iso2 && _vm.tmpValue === item.iso2 }
          ],style:([
            _vm.itemHeight ]),attrs:{"tabindex":"-1","type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.updateValue(item.iso2)}}},[(!_vm.noFlags)?_c('div',{staticClass:"country-selector__list__item__flag-container"},[_c('div',{class:("iti-flag-small iti-flag " + (item.iso2.toLowerCase()))})]):_vm._e(),(_vm.showCodeOnList)?_c('span',{staticClass:"country-selector__list__item__calling-code flex-fixed"},[_vm._v("+"+_vm._s(item.dialCode))]):_vm._e(),_c('div',{staticClass:"dots-text"},[_vm._v(" "+_vm._s(item.name)+" ")])])])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }