<template lang="html">
  
  <div class="v-autocomplete">
    <div class="v-autocomplete-input-group form-group" :class="{'v-autocomplete-selected': value, 'input-group has-danger': errclass, 'input-group has-success':successclass}">
      <input type="search" v-model="searchText" v-bind="inputAttrs" 
            :class="inputAttrs.class || inputClass"
            :placeholder="inputAttrs.placeholder || placeholder"
            :disabled="inputAttrs.disabled || disabled"
            @blur="blur" @focus="focus" @input="inputChange"
            @keyup.enter="keyEnter" @keydown.tab="keyEnter" 
            @keydown.up="keyUp" @keydown.down="keyDown">
            <span v-if="errclass || successclass" class="input-group-append">
              <div @click="onRevertItem" class="input-group-text" :class="{'revert-failed': reverterrclass, 'revert-success':revertsuccessclass}">
                <i class="tim-icons icon-refresh-01"></i>
              </div>
            </span>
    </div>
    <div class="v-autocomplete-list arrow-top" v-if="show">
      <div class="v-autocomplete-list-item" v-for="item, i in internalItems" @click="onClickItem(item)"
           :class="{'v-autocomplete-item-active': i === cursor}" @mouseover="cursor = i">
        <div :is="componentItem" :item="item" :searchText="searchText"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Item from './Item.vue'
import utils from './utils.js'

export default {
  name: 'v-autocomplete',
  props: {
    errclass: Boolean,
    successclass: Boolean,
    componentItem: { default: () => Item },
    minLen: { type: Number, default: utils.minLen },
    wait: { type: Number, default: utils.wait },
    value: null,
    getLabel: {
      type: Function,
      default: item => item
    },
    items: Array,
    autoSelectOneItem: { type: Boolean, default: true },
    placeholder: String,
    inputClass: {type: String, default: 'v-autocomplete-input'},
    disabled: {type: Boolean, default: false},
    inputAttrs: {type: Object, default: () => {return {}}},
    keepOpen: {type: Boolean, default: false}
  },
  data () {
    return {
      searchText: '',
      showList: false,
      cursor: -1,
      reverterrclass: false,
      revertsuccessclass: false,
      internalItems: this.items || []
    }
  },
  computed: {
    hasItems () {
      return !!this.internalItems.length
    },
    show () {
      return (this.showList && this.hasItems) || this.keepOpen
    }
  },
  methods: {
    inputChange () {
      this.showList = true
      this.cursor = -1
      //console.log(this.searchText + ' | ' + this.updateItems)
      //this.onSelectItem(null, 'inputChange')
      utils.callUpdateItems(this.searchText, this.updateItems)
      this.$emit('change', this.searchText)
    },

    updateItems () {
      this.$emit('update-items', this.searchText)
    },

    focus () {
      if (this.$props.successclass) {
        this.revertsuccessclass = true;
        this.reverterrclass = false;
      }
      if (this.$props.errclass) {
        this.reverterrclass = true;
        this.revertsuccessclass = false;
      }
      //console.log(this.internalItems.length);
      this.$emit('focus', this.searchText)
      if (this.internalItems.length == 1 && this.internalItems[0].id == null) {
        this.showList = false
      }else{
        this.showList = true
      }
    },

    blur () {
      this.reverterrclass = false;
      this.revertsuccessclass = false;
      this.$emit('blur', this.searchText)
      setTimeout( () => this.showList = false, 200)
    },

    onClickItem(item) {
      this.onSelectItem(item)
      this.$emit('item-clicked', item)
    },

    onRevertItem() {
      this.$emit('revert-item',this.searchText)
    },

    onSelectItem (item) {
      if (item) {
        this.internalItems = [item]
        this.searchText = this.getLabel(item)
        this.$emit('item-selected', item)
      } else {
        this.setItems(this.items)
      }
      this.$emit('input', item)
    },

    setItems (items) {
      this.internalItems = items || []
    },

    isSelectedValue (value) {
      return 1 == this.internalItems.length && value == this.internalItems[0]
    },

    keyUp (e) {
      if (this.cursor > -1) {
        this.cursor--
        this.itemView(this.$el.getElementsByClassName('v-autocomplete-list-item')[this.cursor])
      }
    },

    keyDown (e) {
      console.log("keydown : " + this.internalItems.length)
      if (this.cursor < this.internalItems.length) {
        this.cursor++
        this.itemView(this.$el.getElementsByClassName('v-autocomplete-list-item')[this.cursor])
      }
    },

    itemView (item) {
      if (item && item.scrollIntoView) {
        item.scrollIntoView(false)
      }
    },

    keyEnter (e) {
      if (this.showList && this.internalItems[this.cursor]) {
        this.onSelectItem(this.internalItems[this.cursor])
        this.showList = false
      }
    },

  },
  created () {
    utils.minLen = this.minLen
    utils.wait = this.wait
    this.onSelectItem(this.value)
  },
  watch: {
    items (newValue) {
      this.setItems(newValue)
      let item = utils.findItem(this.items, this.searchText, this.autoSelectOneItem)
      if (item) {
        this.onSelectItem(item)
        this.showList = false
      }
    },
    value (newValue) {
      if (!this.isSelectedValue(newValue) ) {
        this.onSelectItem(newValue)
        this.searchText = this.getLabel(newValue)
      }
    }
  }
}
</script>

<style>
  .v-autocomplete {
    position: relative;
  }
  .v-autocomplete .v-autocomplete-list {
    position: absolute;
  }
  .v-autocomplete .v-autocomplete-list .v-autocomplete-list-item {
    cursor: pointer;
  }
  .v-autocomplete .v-autocomplete-list .v-autocomplete-list-item.v-autocomplete-item-active {
    background-color: #f3f6fa;
  }

  .v-autocomplete-input-group .input-group-append {
    margin-left: 0px;
  }

  .has-danger.v-autocomplete-input-group .input-group-append .input-group-text {
    background-color: rgba(222, 222, 222, 0.1);
    cursor: pointer;
  }
  
  .has-success.v-autocomplete-input-group .input-group-append .input-group-text {
    background-color: transparent !important;
    cursor: pointer;
    /*background-color: rgba(222, 222, 222, 0.1);
    border: 1px solid #2b3553;
    border-radius: 0.4285rem;
    border-left: none;
    color: #ffffff;
    margin: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-right: 20px;
    */
  }
  .has-success.v-autocomplete-input-group .input-group-append .input-group-text.revert-success {
     border-color: #00bf9a;
  }

  .v-autocomplete-input-group .form-control {
    padding-right: 10px !important;
  }

  .has-success.v-autocomplete-input-group:after, .has-danger.v-autocomplete-input-group:after {
    content: none;
  }
</style>
