const secretKey = '3ZY@l9qxdwL6'

export function encryptData(data) {
    try {
        return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    } catch (error) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('userData');
        window.location.href = '/login';
    }
}

export function decryptData(encryptedData) {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('userData');
        window.location.href = '/login';
    }
}