<template>
  <el-tooltip
    content="Sidebar toggle"
    effect="light"
    :open-delay="300"
    placement="right"
  >
    <div
      class="close-open-sidebar bg-bar-color z"
      @click.stop="minimizeSidebar"
      :class="isMinimized ?' is-maximized' :  'is-minimized' "
      rel="tooltip"
      data-original-title="Sidebar toggle"
      data-placement="right"
      style="
    z-index: 2000;
"
    >
      <i class="text-bar-color fa-solid fa-chevron-left visible-on-sidebar-regular"></i>
  
      <i class="text-bar-color fa-solid fa-chevron-right visible-on-sidebar-mini"></i>
    </div>
  </el-tooltip>
</template>
<script>
export default {
  name: 'sidebar-toggle-button',
  computed:{
    isMinimized(){
      return this.$sidebar.isMinimized
    },
    isMobile() {
      return window.innerWidth <= 768;
    }
  },
  methods: {
    minimizeSidebar() {
      // Remove this code if you don't want to display notifications while minimizing sidebar
      let isMinimizedText = this.$sidebar.isMinimized
        ? 'deactivated'
        : 'activated';
      // this.$notify({
      //   type: 'primary',
      //   message: `Sidebar mini ${isMinimizedText}...`,
      //   icon: 'tim-icons icon-bell-55'
      // });
      this.$sidebar.toggleMinimize();
    }
  }
};
</script>
<style>
.close-open-sidebar{
  height: 25px;
  width: 25px;
  background: black;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content:center;
  position: fixed;
  top: 5px;
  z-index: 99;
  cursor: pointer;
  transition: all .3s cubic-bezier(0.685, 0.0473, 0.346, 1);
}
.close-open-sidebar.is-maximized{
  left: 218px;
}
.close-open-sidebar.is-minimized{
  left: 5px;
}
</style>
