<template lang="html">
  <div>{{item}}</div>
</template>

<script>
export default {
  props: {
    item: { required: true }
  }
}
</script>
