<template>
  <footer class="footer">
    <!--<div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <a
            href="http://creative-tim.com"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            Creative Tim
          </a>
        </li>
        <li class="nav-item">
          <a
            href="https://www.creative-tim.com/presentation"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            About Us
          </a>
        </li>
        <li class="nav-item">
          <a
            href="http://blog.creative-tim.com"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            Blog
          </a>
        </li>
      </ul>
      <div class="copyright">
              &copy; {{ year }} - made with by
              <a href="https://uncommonreach.com" target="_blank" style="color:red">UncommonReach Team</a>
      </div>
    </div>-->
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  },
  methods: {
    get_agency_embeddedcode() {
          this.userData = this.$store.getters.userData;

          var _settingname = 'rootAgencyEmbeddedCode';

          this.$store.dispatch('getGeneralSetting', {
              companyID: this.userData.company_parent,
              settingname: _settingname,
          }).then(response => {
              if (response.data != '') {
                  if (response.data.placeEmbedded == 'head') {
                    $('head').append(response.data.embeddedcode);
                  }else if (response.data.placeEmbedded == 'body') {
                    $('body').append(response.data.embeddedcode);
                  }else {
                    $('footer').append(response.data.embeddedcode);
                  }
              }
          },error => {
                
          });
      },
  },
  mounted() {
    /** GET AGENCY EMBEDDED CODE */
    if (!this.$global.systemUser && (this.$global.menuUserType != 'sales')) {
      this.get_agency_embeddedcode();
    }
    /** GET EMAIL CONFIGURATION */
    /** DISABLED RIGHT CLICK AND CONSOLE WARNING IF NOT GLOBAL VIEW */
    if (this.$global.globalviewmode == false && this.$global.systemUser == false) {
      // window.addEventListener('contextmenu', function (e) {
      //    e.preventDefault();
      // });
      
      function consoleWarning() {
          console.log("%cWarning!", "color: red; font-size: 24px;");
          console.log("%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable a feature or 'hack' someone's account, it is a scam and will give them access to your account.", "font-size: 16px;");
      }

      // Check if console is open
      var consoleOpened = false;
      setInterval(function() {
          if (!consoleOpened) {
              consoleOpened = true;
              consoleWarning();
          }
      }, 1000);

    }
    /** DISABLED RIGHT CLICK AND CONSOLE WARNING IF NOT GLOBAL VIEW */
  },
};
</script>
<style>
.modal-content .modal-header .title {
    color: #222a42 !important;
}

.modal-content .modal-body p {
    color: #222a42 !important;
}

.modal-content .modal-body a {
    color: #942434 !important;
}

 .modal-content .modal-body  small {
    color: #525f7f !important;
}
.modal-content .modal-body label{
  margin-bottom: 0 !important;
}
.swal2-title, .dropdown-navbar a {
    color: #525f7f !important;
}

.modal-content .modal-body .dropbox p {
    color: #FFF !important;
}

#locatorWhitelist .modal-content .modal-body p {
    color: #FFF;
}

.search-input .el-input__inner {
    /* border-color: #FFF; */
}

.dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: var(--gray);
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
 /*  background: #27293d; when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
  
</style>
