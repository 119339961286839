<template>
  <div
    ref="parent"
    :class="[{
      'is-focused': isFocus,
      'is-valid': valid,
      'has-value': value,
      'has-error': error,
      'is-disabled': disabled,
      'is-dark': dark,
      'has-hint': hint
    }, size]"
    class="input-tel"
    @click="focusInput"
    @mouseenter="updateHoverState(true)"
    @mouseleave="updateHoverState(false)"
  >
    <input
      :id="id"
      ref="InputTel"
      v-model="inputValue"
      v-bind="$attrs"
      :placeholder="labelValue"
      :type="type"
      class="input-tel__input"
      :disabled="disabled"
      :required="required"
      :class="{ 'no-country-selector': noCountrySelector }"
      :style="[
      ]"
      @keydown="keyDown"
      @keyup="keyUp"
      @focus="onFocus"
      @blur="onBlur"
      @click="$emit('click', $event)"
    >
    <label
      ref="label"
      :for="id"
	  v-if="showLabel"
      :class="error ? 'text-danger' : null"
      class="input-tel__label"
      @click="focusInput"
    >
      {{ hintValue || labelValue }}
    </label>

    <button
      v-if="clearable && inputValue"
      class="input-tel__clear"
      title="clear"
      type="button"
      tabindex="-1"
      @click="clear"
    >
      <span class="input-tel__clear__effect" />
      <span>
        ✕
      </span>
    </button>

    <div
      v-if="loader"
      class="input-tel__loader"
    >
      <div
        
        class="input-tel__loader__progress-bar"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'InputTel',
    props: {
      value: { type: [String, Number], default: null },
      label: { type: String, default: 'Enter text' },
      hint: { type: String, default: null },
      error: { type: Boolean, default: Boolean },
      disabled: { type: Boolean, default: false },
      dark: { type: Boolean, default: false },
      id: { type: String, default: 'InputTel' },
      size: { type: String, default: null },
      type: { type: String, default: 'tel' },
      readonly: { type: Boolean, default: false },
      showLabel: { type: Boolean, default: true },
      valid: { type: Boolean, default: false },
      required: { type: Boolean, default: false },
      loader: { type: Boolean, default: false },
      clearable: { type: Boolean, default: false },
      noCountrySelector: { type: Boolean, default: false }
    },
    data () {
      return {
        isFocus: false,
        isHover: false
      }
    },
    computed: {
      inputValue: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
      labelValue () {
        const { label } = this
        return this.required && label ? `${label} *` : label
      },
      hintValue () {
        const { hint } = this
        return this.required && hint ? `${hint} *` : hint
      }
    },
    methods: {
      updateHoverState(value) {
        this.isHover = value
      },
      focusInput () {
        this.$refs.InputTel.focus()
      },
      onFocus: function () {
        this.$emit('focus')
        this.isFocus = true
      },
      onBlur: function () {
        this.$emit('blur')
        this.isFocus = false
      },
      clear () {
        this.$emit('input', null)
        this.$emit('clear')
      },
      keyUp (e) {
        this.$emit('keyup', e)
      },
      keyDown (e) {
        this.$emit('keydown', e)
      }
    }
  }
</script>

<style lang="scss" scoped>
 .input-tel {
	 position: relative;
	 font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	 height: 40px;
	 min-height: 40px;
}
 .input-tel__label {
	 position: absolute;
	 top: 4px;
	 cursor: pointer;
	 left: 13px;
	 transform: translateY(25%);
	 opacity: 0;
	 transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
	 font-size: 11px;
	//  color: #747474;
}
 .input-tel__input {
	 cursor: pointer;
	 background-color: transparent;
	 transition-duration: 0.3s;
	 position: relative;
	 width: 100%;
	 padding: 0 12px;
	 font-weight: 400;
	 appearance: none;
	 outline: none;
	 border: 1px solid #cad1d7;
	 font-size: 14px;
	 z-index: 0;
	 margin-left: -1px;
	 height: 40px;
	 min-height: 40px;
   color: var(--gray-900);
   border-radius: 0px 6px 6px 0px;
   
}
 .input-tel__input:hover {
	 border-color: dodgerblue;
}
 .input-tel__input:not(.no-country-selector) {
	 border-top-left-radius: 0 !important;
	 border-bottom-left-radius: 0 !important;
}
 .input-tel__input::-webkit-input-placeholder {
	//  color: #747474;
}
 .input-tel__input::-moz-placeholder {
	//  color: #747474;
}
 .input-tel__input:-ms-input-placeholder {
	//  color: #747474;
}
 .input-tel__input::-ms-input-placeholder {
	//  color: #747474;
}
 .input-tel__input:-moz-placeholder {
	//  color: #747474;
}
 .input-tel__input::placeholder {
	//  color: #747474;
}
 .input-tel__input__input:-webkit-autofill, .input-tel__input__input:-webkit-autofill:hover, .input-tel__input__input:-webkit-autofill:focus, .input-tel__input__input:-webkit-autofill:active {
	 box-shadow: 0 0 0 1000px white inset !important;
	 -webkit-text-fill-color: var(--primary-input-text-color) !important;
}
 .input-tel__clear {
	 position: absolute;
	 top: 0;
	 bottom: 0;
	 right: 8px;
	 margin: auto 0;
	 width: 24px;
	 height: 24px;
	 appearance: none;
	 border: none;
	 background: transparent;
	//  color: #747474;
	 border-radius: 24px;
	 cursor: pointer;
	 font-size: 12px;
}
 .input-tel__clear:focus {
	 outline: none;
}
 .input-tel__clear > span:not(.input-tel__clear__effect) {
	 position: relative;
	 top: 1px;
}
 .input-tel__clear__effect {
	 position: absolute;
	 top: 0;
	 left: 0;
	 right: 0;
	 bottom: 0;
	 width: 24px;
	 height: 24px;
	 background-color: #747474;
	 border-radius: 24px;
	 transform: scale(0);
	 transition: transform 200ms;
}
 .input-tel__clear:hover {
	 color: white;
}
 .input-tel__clear:hover .input-tel__clear__effect {
	 transform: scale(1);
	 opacity: 0.6;
}
 .input-tel.is-dark .input-tel__input::-webkit-input-placeholder {
	 color: rgba(255, 255, 255, 0.7);
}
 .input-tel.is-dark .input-tel__input::-moz-placeholder {
	 color: rgba(255, 255, 255, 0.7);
}
 .input-tel.is-dark .input-tel__input:-ms-input-placeholder {
	 color: rgba(255, 255, 255, 0.7);
}
 .input-tel.is-dark .input-tel__input::-ms-input-placeholder {
	 color: rgba(255, 255, 255, 0.7);
}
 .input-tel.is-dark .input-tel__input:-moz-placeholder {
	 color: rgba(255, 255, 255, 0.7);
}
 .input-tel.is-dark .input-tel__input::placeholder {
	 color: rgba(255, 255, 255, 0.7);
}
 .input-tel.is-dark .input-tel__input__input:-webkit-autofill, .input-tel.is-dark .input-tel__input__input:-webkit-autofill:hover, .input-tel.is-dark .input-tel__input__input:-webkit-autofill:focus, .input-tel.is-dark .input-tel__input__input:-webkit-autofill:active {
	 box-shadow: 0 0 0 1000px #21222e inset !important;
	 -webkit-text-fill-color: red !important;
}
 .input-tel.is-dark .input-tel__clear {
	 color: rgba(255, 255, 255, 0.7);
}
 .input-tel.is-dark .input-tel__clear__effect {
	 background-color: rgba(255, 255, 255, 0.3);
}
 .input-tel.is-dark .input-tel__clear:hover {
	 color: white;
}
 .input-tel.is-focused {
	 z-index: 1;
}
 .input-tel.is-focused .input-tel__input {
	 border-color: dodgerblue;
	 box-shadow: 0 0 0 0.125rem rgba(30, 144, 255, 0.7);
}
 .input-tel.is-focused .input-tel__label {
	 color: dodgerblue;
}
 .input-tel.is-focused.has-error .input-tel__input {
	 box-shadow: 0 0 0 0.125rem rgba(255, 69, 0, 0.7);
}
 .input-tel.is-focused.is-valid .input-tel__input {
	 border-color: yellowgreen;
	 box-shadow: 0 0 0 0.125rem rgba(154, 205, 50, 0.7);
}
 .input-tel.has-value .input-tel__label {
	 opacity: 1;
	 transform: translateY(0);
	 font-size: 11px;
}
 .input-tel.has-value .input-tel__input {
	 padding-top: 14px;
}
 .input-tel.has-value .input-tel__label, .input-tel.has-hint .input-tel__label {
	 opacity: 1;
	 transform: translateY(0);
	 font-size: 11px;
}
 .input-tel.has-value .input-tel__input, .input-tel.has-hint .input-tel__input {
	 padding-top: 14px;
}
 .input-tel.is-valid .input-tel__input, .input-tel.is-valid .input-tel__input:hover {
	 border-color: yellowgreen;
}
 .input-tel.is-valid .input-tel__label {
	 color: yellowgreen;
}
 .input-tel.has-error:not(.is-valid) .input-tel__input {
	 border-color: orangered;
}
 .input-tel.has-error:not(.is-valid) .input-tel__label {
	 color: orangered;
}
 .input-tel.is-disabled {
	 cursor: not-allowed;
}
 .input-tel.is-disabled .input-tel__input {
	 border-color: #ccc;
	 background-color: #f2f2f2;
	 color: #ccc;
}
 .input-tel.is-disabled .input-tel__input::-webkit-input-placeholder {
	 color: #ccc;
}
 .input-tel.is-disabled .input-tel__input::-moz-placeholder {
	 color: #ccc;
}
 .input-tel.is-disabled .input-tel__input:-ms-input-placeholder {
	 color: #ccc;
}
 .input-tel.is-disabled .input-tel__input::-ms-input-placeholder {
	 color: #ccc;
}
 .input-tel.is-disabled .input-tel__input:-moz-placeholder {
	 color: #ccc;
}
 .input-tel.is-disabled .input-tel__input::placeholder {
	 color: #ccc;
}
 .input-tel.is-disabled .input-tel__label, .input-tel.is-disabled .input-tel__input, .input-tel.is-disabled .input-tel__toggle__arrow {
	 cursor: not-allowed;
	 color: #ccc;
}
 .input-tel.sm {
	 height: 36px;
	 min-height: 36px;
}
 .input-tel.sm .input-tel__input {
	 font-size: 12px;
	 height: 36px;
	 min-height: 36px;
}
 .input-tel.sm .input-tel__label {
	 font-size: 10px;
}
 .input-tel.sm.has-value .input-tel__input {
	 padding-top: 12px;
}
 .input-tel.lg {
	 height: 48px;
	 min-height: 48px;
}
 .input-tel.lg .input-tel__input {
	 font-size: 14px;
	 height: 48px;
	 min-height: 48px;
}
 .input-tel.lg .input-tel__label {
	 font-size: 14px;
}
 .input-tel.lg.has-value .input-tel__input {
	 padding-top: 16px;
}
 .input-tel__loader {
	 bottom: 0;
	 height: 2px;
	 left: 0;
	 width: calc(100% - 8px);
	 position: absolute;
	 overflow: hidden;
	 border-radius: 8px;
}
 .input-tel__loader__progress-bar {
	 background-color: dodgerblue;
	 display: block;
	 position: absolute;
	 content: '';
	 left: -200px;
	 width: 200px;
	 height: 2px;
	 animation: loading 2s linear infinite;
}
 @keyframes loading {
	 from {
		 left: -200px;
		 width: 30%;
	}
	 50% {
		 width: 30%;
	}
	 70% {
		 width: 70%;
	}
	 80% {
		 left: 50%;
	}
	 95% {
		 left: 120%;
	}
	 to {
		 left: 100%;
	}
}
 
</style> 
